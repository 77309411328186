// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import "bootstrap"
import 'lodash'
require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/ui/data");
require('jquery-ui/themes/base/all.css');

$( document ).on('turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip()
  let fetchSuggestions = function(term){
    $('#recipe_suggestions').html('')
    $.get('recipe_suggestions', {term: term})
      .done(function(data){
        $('#recipe_suggestions').html(data)
      })
  }
  let memoizedSuggestions = _.memoize(fetchSuggestions)
  let refreshRecipeSuggestions = function(){
    let term = $('#recipe_suggestion_input').val()
    if(term){
      memoizedSuggestions(term)
    }
  }
  $("#recipe_suggestion_input").on('keyup', _.debounce(refreshRecipeSuggestions, 400))
})
