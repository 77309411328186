import consumer from "./consumer"

$( document ).on('turbolinks:load', function() {
  let menuId = $('#menu').data('id')
  if(menuId && $('[data-importing]').length){
    consumer.subscriptions.create({channel: "MenuChannel", id: menuId}, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        Turbolinks.visit(location.toString());
      }
    });
  }
})
